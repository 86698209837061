<template>
  <div>
    <detailComponent
      :bannerList="bannerList"
      :srcList="srcList"
      :Info="Info"
      :versionInfo="versionInfo"
      :likecount="likecount"
      :SimList="SimList"
      :listLength="listLength"
      :activeIndex="activeIndex"
      @ListVersion="ListVersion"
      @commentPublish="commentPublish"
      @PartnerProductDetail="PartnerProductDetail"
      @ServiceSupport="ServiceSupport"
      @TagMode="TagMode"
    ></detailComponent>
  </div>
</template>
<script>
import detailComponent from '@/components/myself/poductDetail/detailComponent'
import { queryProductDetail } from '@/api/cloudmarket/apporoval.js'

export default {
  components: { detailComponent },
  props: {
    id: {
      type: String
    }
  },
  data () {
    return {
      Info: {
        type: '',
        specsPriceDTOList: [],
        specsDTOList: [],
        mode: '',
        name: '',
        id: '',// 也是搜索关键字
        author: '',
        loginAuthor: '',
        size: '',
        length: '',
        updateTime: '',
        description: '',
        Likeds: false,
        statistic: {},
        backNum: '',
        optionIndex: '',
        searchName: '',
        condition: '',
        ListType: '',
        ListTypeName: '',
        ListTypeIndex: '',
        isPurchased: '',
        price: {},
        isOrder: '',
        priceIndex: '',
        sign: '',
        detailUrlList: [],
        chargeModeTypeList: [],
        afterService: {},
        useWay: '',
        url: '',
        detailUrlResponse: {},
        partnerAudit: {},
        gradeName: '',
        linkClick: 0,
        IFpayMoney: false

      },
      versionInfo: {
        versionDetail: '',
        versionList: [],
        ClickIndex: 0,
        detail: '',
        price: {},
        currentVersion: '',
        id: '',
        size: ''
      },
      SimList: [],
      enterDiv: false,
      listLength: 0,
      listPage: 0,
      addOrderFlag: false,


      // 
      bannerList: {
        imgList: [],
        index: 0,// 当前点击的图片的索引
      },
      srcList: [],
      querypage: {
        pageNum: 1,
        pageSize: 3
      },
      likecount: 0,

      activeIndex: '1'

    }
  },
  mounted () {
    this.Info.id = this.id
    this.Detailinfo()
  },
  methods: {
    async Detailinfo () {
      this.bannerList = {
        imgList: [],
        index: 0,
      }
      const res = await queryProductDetail(this.Info.id)
      if (res.status !== 200) return this.$message.error('失败的获取模板详情')
      if (res.data.status !== 200) return this.$message.error(res.data.message)

      let infoList = res.data.data
      const { versions = []} = infoList

      infoList.pictureUrlList.forEach(img => {
        this.bannerList.imgList.push({ img: img })
      })
      this.srcList = infoList.pictureUrlList
      this.Info.mode = infoList.mode;
      this.Info.specsPriceDTOList = infoList.s
      this.Info.specsPriceDTOList = infoList.specsPriceDTOList || []
      this.Info.specsDTOList = infoList.specsDTOList || []
      this.Info.Labels = infoList.categoryNameList
      this.Info.author = infoList.ownerName
      this.Info.updateTime = infoList.updateTime
      this.Info.description = infoList.detail
      this.Info.statistic = infoList.statistic
      this.versionInfo.versionList = versions || []
      this.Info.price = infoList.price
      this.Info.ListType = infoList.type
      this.Info.sign = infoList.sign
      this.Info.chargeModeTypeList = infoList.auditChargeModeTypeList
      this.Info.detailUrlList = infoList.detailUrlList
      this.Info.afterService = infoList.afterService
      this.Info.useWay = infoList.useWay
      this.Info.detailUrlResponse = infoList.detailUrlResponse
      this.Info.url = infoList.url
      this.Info.partnerAudit = infoList.partnerAudit
      this.Info.gradeName = infoList.gradeName
      this.Info.IFpayMoney = this.Info.isPurchased || this.Info.price.price <= 0 || this.Info.loginAuthor === this.Info.author
      this.Info.type = infoList.type
      let size = ''
      try {
        size = versions[0].resPackage.size
      } catch (error) {
        size = ''
      }
      this.versionInfo.size = infoList.sign ? '' : size
    },
    ListVersion () {
      this.activeIndex = '1'
    },
    commentPublish () {
      this.activeIndex = '2'
    },
    PartnerProductDetail () {
      this.activeIndex = '1'
    },
    ServiceSupport () {
      this.activeIndex = '3'
    },
    TagMode (index) {
      this.Info.linkClick = index
    },
  }
}
</script>
<style lang="less" scoped>
.breadcrumb {
  margin-left: 100px;
  margin-top: 20px;
  font-size: var(--Font18);
}
</style>