var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.bannerList.imgList),function(banner,index){return _c('swiper-slide',{key:index},[_c('el-image',{style:([
          true
            ? {
                height: _vm.Height ? Number(_vm.Height) + 'px' : '100%',
                width: '100%',
              }
            : {},
        ]),attrs:{"src":banner.img,"fit":"contain","preview-src-list":_vm.srcList}})],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerList.imgList.length > 1),expression:"bannerList.imgList.length > 1"}],staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }