<template>
  <div>
    <el-row v-show="!isH5">
      <h3 class="h33" v-show="commentName">发表{{ name }}</h3>
      <el-input
        type="textarea"
        v-model="CommentContent"
        placeholder="请输入内容"
        maxlength="200"
        show-word-limit
        clearable
        rows="4"
        v-show="!isH5"
      ></el-input>

      <div style="float: right; margin-top: 5px">
        <el-button>清空</el-button>
        <el-button type="primary">发表</el-button>
      </div>
    </el-row>
    <el-row>
      <h3 class="h33">{{ name }}列表({{ allTotal }}条)</h3>
    </el-row>
    <div style="font-size: 15px">
      <div v-if="total1 == 0" style="display: inline-block">
        <el-empty
          :image="imgurl"
          :description="description"
          :image-size="80"
        ></el-empty>
      </div>
      <div v-else>
        <div v-for="(item, index) in CommentForm" :key="index">
          <!-- 父 显示 parentId-->
          <commentShowComponet
            :item="item"
            :isH5="isH5"
            :Splaceholder="Splaceholder"
          ></commentShowComponet>
          <!-- 子 显示 replyNum-->
          <el-row>
            <div style="margin-left: 40px; width: 90%">
              <div v-for="(item1, index1) in item.ChildernList" :key="index1">
                <commentShowComponet
                  :item="item1"
                  :isH5="isH5"
                  :Splaceholder="Splaceholder"
                ></commentShowComponet>
              </div>
              <div style="display: flex; margin-top: 20px">
                <el-link
                  :underline="false"
                  style="margin-left: 50%"
                  v-if="item.PageSize < item.ChildernListTotal"
                  >展开{{ name }}</el-link
                >
                <el-link
                  :underline="false"
                  v-if="item.flag"
                  :style="
                    item.PageSize < item.ChildernListTotal
                      ? {
                          marginLeft: '20px',
                          color: 'grey',
                        }
                      : { marginLeft: '50%', color: 'grey' }
                  "
                  >收起</el-link
                >
              </div>
            </div>
          </el-row>
        </div>
      </div>
    </div>

    <el-row>
      <div v-if="querypage1.pageSize < total1">
        <el-link :underline="false" style="width: 100%; margin-top: 40px"
          >查看更多{{ name }}</el-link
        >
      </div>
    </el-row>
  </div>
</template>
<script>
import commentShowComponet from './commentShow.vue'

export default {
  components: { commentShowComponet },
  props: {
    isH5: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: '评论'
    },
    description: {
      type: String,
      default: '暂无评论'
    },
    showId: {
      type: [String, Number],
    },
    typeName: {
      type: String,
      default: ''
    },
    commentName: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      showName: '',
      CommentObejctId: '',
      CommentContent: '',
      CommentForm: [],
      wordnum: 0,
      wordnum1: 0,
      querypage1: {
        pageNum: 1,
        pageSize: 5
      },
      querypage2: {
        pageNum: 1,
        pageSize: 2
      },
      allTotal: 0,
      total1: 0,
      Splaceholder: '',
      userimg: '',
      imgurl: require('@/assets/imgs/评论.png')
    }
  }
}
</script>
<style lang="less" scoped>
.h33 {
  float: left;
  font-size: 18px;
}
/deep/ .el-avatar {
  margin-right: 20px;
}

/deep/ .el-empty__description p {
  color: #787878;
}
</style>
