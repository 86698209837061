import { getTopAll } from "@/api/cloudmarket/objectType";
import ElementUI from "element-ui";

export async function GetCacheTopAll() {
  let key = "GET_TOP_ALL";
  let data = sessionStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    const res = await getTopAll();
    if (res.status !== 200) {
      ElementUI.Message.error("获取失败");
      return;
    }
    if (res.data.status !== 200) {
      ElementUI.Message.error(res.data.message);
      return;
    }
    const resData = res.data.data;
    sessionStorage.setItem(key, JSON.stringify(resData));
    return resData;
  }
}
