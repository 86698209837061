<template>
  <div>
    <el-row
      style="margin-top: 20px; word-break: break-all; word-break: break-word"
    >
      <div
        style="display: flex"
        @mouseenter="enterImg(item)"
        @mouseleave="leaveImg(item)"
      >
        <div style="text-align: left; width: 85%;">
          <div style="display: flex">
            <el-avatar shape="square" size="small" :src="item.userImageUrl">
            </el-avatar>
            <!-- 父评论 -->
              <div style="width: 90%;"  v-if="item.parentId === 0">
                <span style="font-weight: bold; line-height: 2"
                  >{{ item.userName
                  }}<span
                    style="color: grey; font-size: 10px; margin-left: 20px"
                  >
                    {{ item.createTime1 }}</span
                  ></span
                >
                <br />
                <span style="font-family: '微软雅黑'; line-height: 1.5">
                  {{ item.content }}</span
                >
              </div>
              <!-- 子评论 -->
              <div style="text-align: left; width: 95%;" v-else>
                <b style="line-height: 2">
                  {{ item.userName
                  }}<span style="color: grey; font-size: 10px"> 回复 </span
                  >{{ item.parentUserName }}
                  <span
                    style="color: grey; font-size: 10px; margin-left: 20px"
                    >{{ item.createTime1 }}</span
                  >
                </b>
                <br />
                <span style="font-family: '微软雅黑'; line-height: 1.5">
                  {{ item.content }}</span
                >
              </div>
          </div>
        </div>
        <div style="text-align: right; width: 15%" v-show="!isH5">
          <el-link
            :underline="false"
            icon="el-icon-s-comment"
            @click="ReplyTOComment(item)"
            v-if="item.enterDiv"
          >
            {{ item.ReplyDialog === true ? "取消回复" : "回复" }}
          </el-link>
        </div>
      </div>
    </el-row>
    <!-- 发表 -->
    <el-row>
      <div v-if="item.ReplyDialog == true" style="margin-top: 5px">
        <el-input
          type="textarea"
          v-model="item.CommentContent"
          :placeholder="Splaceholder"
          maxlength="200"
          @input="DesText1(item)"
          show-word-limit
          clearable
          rows="4"
        ></el-input>

        <el-button
          type="primary"
          style="float: right; margin-top: 5px"
          @click="CommentInfo1(item)"
          >发表
        </el-button>
      </div>
    </el-row>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    },
    isH5: {
      type: Boolean,
      default: false
    },
    Splaceholder: {
      type: String,
    },
  },
  methods: {
    ReplyTOComment (item) {
      this.$emit('ReplyTOComment', item)
    },
    DesText1 (item) {
      this.$emit('DesText1', item)
    },
    CommentInfo1 (item) {
      this.$emit('CommentInfo1', item)
    },

    // 获取鼠标悬停事件
    enterImg (para) {
      this.$set(para, 'enterDiv', true)
    },
    leaveImg (para) {
      this.$set(para, 'enterDiv', false)
    },
  }
}
</script>