<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>商品管理</el-breadcrumb-item>
      <el-breadcrumb-item>商品上架审核</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-menu :default-active="activeIndex" mode="horizontal">
        <el-menu-item index="1" @click="ApproveWait">待审批</el-menu-item>
        <el-menu-item index="2" @click="ApproveRecord">审批记录</el-menu-item>
      </el-menu>
      <div v-if="activeIndex == '1'">
        <el-row :gutter="30" style="margin-top: 40px">
          <el-form
            :model="queryinfo"
            ref="queryinfoRef"
            :inline="true"
            style="text-align: left; margin-left: 40px"
          >
            <el-form-item prop="type">
              <div style="display: inline-block" class="divSpan">
                商品分类：
              </div>
              <div style="display: inline-block">
                <ELselect
                  v-model="queryinfo.type"
                  :options="OneLabelIds"
                  :optionKey="ParentIdkeys"
                  @input="seachChange"
                  @clear="seachChange"
                  Splaceholder="请选择"
                ></ELselect>
              </div>
            </el-form-item>
            <el-form-item prop="uploadtime">
              <div style="display: inline-block" class="divSpan">
                上传时间：
              </div>
              <div style="display: inline-block">
                <el-date-picker
                  type="datetimerange"
                  v-model="queryinfo.uploadtime"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="截止日期"
                  @change="dateSerach"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
              </div>
            </el-form-item>

            <el-button type="primary" icon="el-icon-search" @click="seachChange"
              >查询</el-button
            >
            <el-button type="info" icon="el-icon-refresh" @click="clean"
              >重置</el-button
            >
            <el-button type="primary" @click="partnerV"
              >切换{{ productFlag }}列表</el-button
            >
          </el-form>
        </el-row>

        <el-row :gutter="30" style="display: flex">
          <el-table
            :data="ApproveWaitList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            tooltip-effect="dark"
            :cell-style="{
              textAlign: 'center',
            }"
          >
            <el-table-column type="index" min-width="50px"></el-table-column>
            <el-table-column
              prop="name"
              label="商品名称"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="version"
              label="版本号"
              min-width="100px"
            ></el-table-column>
            <!-- <el-table-column
              prop="detail"
              label="版本描述"
              min-width="300px"
              show-overflow-tooltip
            ></el-table-column> -->
            <el-table-column
              prop="company"
              label="企业名称"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="userId"
              label="用户ID"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="联系手机号"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="申请日期"
              min-width="150px"
            ></el-table-column>
            <el-table-column label="操作" min-width="150px">
              <template slot-scope="scope">
                <el-button
                  type="primary"
                  size="mini"
                  @click="Approve(scope.row)"
                  >审核</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>

      <div v-if="activeIndex == '2'">
        <el-row :gutter="30" style="margin-top: 40px">
          <el-form
            :model="queryinfo"
            ref="queryinfoRef"
            :inline="true"
            style="text-align: left; margin-left: 40px"
          >
            <el-form-item prop="type">
              <div style="display: inline-block" class="divSpan">
                商品分类：
              </div>
              <div style="display: inline-block">
                <ELselect
                  v-model="queryinfo.type"
                  :options="OneLabelIds"
                  :optionKey="ParentIdkeys"
                  @input="seachChange"
                  @clear="seachChange"
                  Splaceholder="请选择"
                ></ELselect>
              </div>
            </el-form-item>
            <el-form-item prop="approvetime">
              <div style="display: inline-block" class="divSpan">
                审核时间：
              </div>
              <div style="display: inline-block">
                <el-date-picker
                  type="datetimerange"
                  v-model="queryinfo.approvetime"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="截止日期"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  @change="dateSerach"
                  :default-time="['00:00:00', '23:59:59']"
                ></el-date-picker>
              </div>
            </el-form-item>

            <el-form-item prop="approveresult">
              <div style="display: inline-block" class="divSpan">
                审核状态：
              </div>
              <div style="display: inline-block">
                <ELselect
                  v-model.trim="queryinfo.approveresult"
                  :options="ResultOptions"
                  :optionKey="ResultOptionKeys"
                  Splaceholder="请选择"
                  @clear="getList1"
                  @input="getList1"
                  style="width: 120px"
                ></ELselect>
              </div>
            </el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="seachChange"
              >查询</el-button
            >
            <el-button type="info" icon="el-icon-refresh" @click="clean"
              >重置</el-button
            >
            <el-button type="primary" @click="partnerV"
              >切换{{ productFlag }}列表</el-button
            >
          </el-form>
        </el-row>

        <el-row :gutter="30" style="display: flex">
          <el-table
            :data="ApproveRecodeList"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            tooltip-effect="dark"
            :cell-style="{
              textAlign: 'center',
            }"
          >
            <el-table-column type="index" min-width="50px"></el-table-column>
            <!-- <el-table-column
              prop="flowNodeName"
              label="审批节点"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="auditorName"
              label="审批员"
              min-width="150px"
            ></el-table-column> -->

            <el-table-column
              prop="productBase.name"
              label="商品名称"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="productBase.version"
              label="版本号"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="company"
              label="企业名称"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="userId"
              label="用户ID"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="phone"
              label="联系手机号"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="申请日期"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="auditInfo"
              label="审批信息"
              min-width="150px"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column label="审核状态" min-width="100px">
              <template slot-scope="scope">
                <el-tag :type="scope.row.status == 2 ? 'danger' : 'success'">
                  {{ scope.row.status === 2 ? "拒绝" : "通过" }}</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="审核时间"
              min-width="150px"
            ></el-table-column>
            <el-table-column
              prop="createTime"
              label="备注"
              min-width="150px"
            ></el-table-column>

            <el-table-column label="操作" min-width="150px">
              <template slot-scope="scope">
                <!-- <el-button type="primary" size="mini" @click="ViewApprove(scope.row)">查看</el-button> -->
                <el-button
                  type="danger"
                  size="mini"
                  @click="DeleteApprove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </div>
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <!-- 查看按钮 -->
      <el-dialog
        :close-on-click-modal="false"
        title="查看信息"
        :visible.sync="viewDialogVisible"
        width="40%"
        @close="viewDialogClosed"
      >
        <el-form :model="viewForm" ref="viewFormRef" label-width="100px">
          <el-form-item label="审批工单id：">
            <el-input v-model.trim="viewForm.orderId"></el-input>
          </el-form-item>
          <el-form-item label="审批节点：">
            <el-input v-model.trim="viewForm.flowNodeName"></el-input>
          </el-form-item>
          <el-form-item label="审批员：">
            <el-input v-model.trim="viewForm.auditorName"></el-input>
          </el-form-item>
          <el-form-item label="审批信息：">
            <el-input v-model.trim="viewForm.auditInfo"></el-input>
          </el-form-item>
          <el-form-item label="审批结果：">
            <el-input v-model.trim="viewForm.status"></el-input>
          </el-form-item>
          <el-form-item label="审批日期：">
            <el-input v-model.trim="viewForm.createTime"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="viewDialogVisible = false"
            >确定</el-button
          >
        </span>
      </el-dialog>

      <!-- 审批过程 -->
      <el-dialog
        :close-on-click-modal="false"
        title="审批"
        :visible.sync="ApprovalDialog"
        width="50%"
        @close="ApprovalDialogClosed"
        center
      >
        <el-row>
          <el-col :span="17">
            <el-descriptions title="审批信息" :column="1" class="pp">
              <el-descriptions-item label="商品名称"
                >{{ approvalForm.name }}
                <el-link
                  type="primary"
                  :underline="false"
                  class="link1"
                  @click="DownFile(fileForm.id)"
                  v-if="!ifHide"
                  >下载文件
                </el-link>
                <el-link
                  type="primary"
                  :underline="false"
                  class="link1"
                  @click="productDetail"
                  >查看商品详情
                </el-link>
              </el-descriptions-item>
              <el-descriptions-item label="商品版本">{{
                approvalForm.version
              }}</el-descriptions-item>
              <el-descriptions-item label="商品描述">{{
                approvalForm.detail
              }}</el-descriptions-item>
              <el-descriptions-item label="商品价格">{{
                priceForm.price * priceForm.discount
              }}</el-descriptions-item>
              <el-descriptions-item label="企业名称">{{
                priceForm.company
              }}</el-descriptions-item>
              <el-descriptions-item label="联系方式">
                <ELselect
                  v-model="contactPhone"
                  :options="ContactIds"
                  :optionKey="ContactIdskeys"
                  Splaceholder="请选择"
                  style="width: 320px; border: none"
                ></ELselect>
              </el-descriptions-item>
              <el-descriptions-item label="审批反馈">
                <el-input
                  class="input1"
                  type="textarea"
                  clearable
                  rows="4"
                  placeholder="请输入审批反馈内容"
                  v-model="feedbackText"
                  maxlength="100"
                  @input="DesText"
                  show-word-limit
                  style="width: 320px"
                ></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </el-col>
          <el-col :span="6" class="pp">
            <el-descriptions title="审批进度" :column="1"> </el-descriptions>
            <div class="step1">
              <el-steps direction="vertical" :active="activeStep">
                <el-step
                  v-for="(item, index) in approvalFLowNode"
                  :title="
                    typeof item.auditorName === 'undefined'
                      ? item.type
                        ? item.roleName + ' ' + '审批中'
                        : item.roleName
                      : item.auditorName
                  "
                  :key="index"
                  :status="
                    item.type
                      ? 'process'
                      : typeof item.status === 'undefined'
                      ? 'wait'
                      : item.status === 1
                      ? 'success'
                      : 'error'
                  "
                >
                  <template slot="description">
                    <p>
                      <span
                        style="
                          width: 100px;
                          display: inline-block;
                          white-space: nowrap;
                          text-overflow: ellipsis;
                        "
                      >
                        {{
                          typeof item.auditorName === "undefined"
                            ? ""
                            : item.createTime
                        }}
                      </span>
                    </p>
                    <p>
                      <span :title="item.auditInfo" class="elDescribe">
                        {{
                          typeof item.auditorName === "undefined"
                            ? ""
                            : item.auditInfo
                        }}</span
                      >
                    </p>
                  </template>
                </el-step>
              </el-steps>
            </div>
          </el-col>
        </el-row>
        <span slot="footer">
          <el-button
            style="width: 100px"
            @click="UnpassApproval"
            v-preventReClick
            >不同意</el-button
          >
          <el-button
            type=" primary"
            style="width: 100px"
            @click="PassApproval"
            v-preventReClick
            >同意</el-button
          >
        </span>
      </el-dialog>

      <!--  -->
      <el-dialog
        :close-on-click-modal="false"
        :visible.sync="viewDialogVisible1"
        width="80%"
        v-if="viewDialogVisible1"
      >
        <div>
          <el-card>
            <el-breadcrumb
              separator-class="el-icon-arrow-right"
              class="breadcrumb"
            >
              <el-breadcrumb-item :to="{}"> 云市场</el-breadcrumb-item>
              <el-breadcrumb-item>{{ approvalForm.name }}</el-breadcrumb-item>
            </el-breadcrumb>
            <MCdetailProduct :id="approvalForm.id"></MCdetailProduct>
          </el-card>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import { getAuditObjectBasePage, getAuditRecordPage, DeleteAuditRecord, getAuditObjectBase, getAudits } from '@/api/cloudmarket/apporoval'
import { deleteDialog } from '@/libs/confirmDialog'
import ELselect from '@/components/myself/el_select'
import { DownFiles } from '@/components/myself/DownloadFile'
import { getTopAllType } from '@/components/myself/apiList'
import MCdetailProduct from '@/components/myself/poductDetail/detailMComponent.vue'
import { queryProductRelationListInfo } from '@/libs/commonApi'
export default {
  components: { ELselect, MCdetailProduct },
  data () {
    return {
      activeIndex: '1',
      queryinfo: {
        uploadtime: '',
        type: '',
        approvetime: '',
        approveresult: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      ApproveWaitList: [],
      ApprovalDialog: false,
      ApproveRecodeList: [],
      // 查看
      viewDialogVisible: false,
      viewForm: {},

      // 审批信息
      approvalForm: {},
      priceForm: {},
      fileForm: {},
      approvalFLowNode: [],
      ApproveId: '',
      resourceId: '',
      activeStep: 0,
      approvalFormRules: {},
      feedbackText: '',
      wordnum: 0,

      ResultOptions: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 1,
          label: '通过'
        },
        {
          value: 2,
          label: '拒绝'
        },
        {
          value: 0,
          label: '待审批'
        },

      ],
      ResultOptionKeys: {
        value: 'value',
        label: 'label',
        label2: ''
      },
      versionId: '',
      downType: {},
      OneLabelIds: [],
      ParentIdkeys: {
        value: 'typeId',
        label: 'typeName',
        label2: ''
      },
      sign: 0,
      signFlag: false,
      productFlag: '',
      ifHide: false,
      productDetailInfo: {},

      viewDialogVisible1: false,

      // 日期:选择今天以及之后的日期
      // pickerOptions: {
      //   disabledDate: time => {
      //     return time.getTime() < Date.now() - 8.64e7
      //   }
      // },

      contactPhone: '',
      ContactIds: [],
      ContactIdskeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
    }
  },
  mounted () {
    this.getOneLevelType()
  },
  methods: {
    async getOneLevelType () {
      this.OneLabelIds = await getTopAllType()
      this.partnerV()
    },
    partnerV () {
      // 1：合作伙伴列表,0：普通商品列表
      if (this.signFlag) {
        this.sign = 1
        this.productFlag = '普通商品'
      } else {
        this.sign = 0
        this.productFlag = '合作伙伴'
      }
      this.signFlag = !this.signFlag
      this.seachChange()
    },
    async getList () {
      let time1 = this.queryinfo.uploadtime ? this.queryinfo.uploadtime[0] : ''
      let time2 = this.queryinfo.uploadtime ? this.queryinfo.uploadtime[1] : ''
      let quiese = { startTime: time1, endTime: time2, type: this.queryinfo.type, sign: this.sign }

      const res = await getAuditObjectBasePage(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ApproveWaitList = res.data.data.list
      this.total = res.data.data.total
    },
    async Approve (para) {
      const res = await getAuditObjectBase(para.versionId ? para.versionId : para.id)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.approvalForm = res.data.data.auditProductBase
      this.approvalFLowNode = res.data.data.auditFlowNodeDTOS
      this.fileForm = res.data.data.auditObjectResourceEntity
      this.priceForm = res.data.data.auditProductPriceEntity
      this.priceForm.company = res.data.data.company
      // orderid
      this.ApproveId = res.data.data.auditProductBase.orderId
      // this.resourceId
      this.versionId = para.versionId
      this.downType = res.data.data.resourceFile
      this.ifHide = Number(res.data.data.hide) == 1 ? true : false
      // contact
      this.ContactIds = await queryProductRelationListInfo()
      this.ApprovalDialog = true
    },
    async productDetail () {
      this.viewDialogVisible1 = true

    },
    ApproveWait () {
      this.activeIndex = '1'
      this.queryinfo.type = ''
      this.seachChange()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      if (this.activeIndex == '1') {
        this.getList()
      } else {
        this.getList1()
      }

    },
    dateSerach () {
      this.seachChange()
    },
    // 
    ApproveRecord () {
      this.activeIndex = '2'
      this.queryinfo.type = ''
      this.seachChange()
    },

    async getList1 () {
      let time1 = this.queryinfo.approvetime ? this.queryinfo.approvetime[0] : ''
      let time2 = this.queryinfo.approvetime ? this.queryinfo.approvetime[1] : ''

      let quiese = { startTime: time1, endTime: time2, status: this.queryinfo.approveresult, type: this.queryinfo.type, sign: this.sign }
      const res = await getAuditRecordPage(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ApproveRecodeList = res.data.data.list
      this.total = res.data.data.total
      // console.log('8888888888888888', this.ApproveRecodeList)
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.seachChange()
    },
    async DeleteApprove (para) {
      const Result = await deleteDialog('确定删除?', this)
      if (Result === 'confirm') {
        const res = await DeleteAuditRecord(para.id)
        if (res.status !== 200) return this.$message.error('删除操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.getList1()
      }
    },
    ViewApprove () {
      this.viewDialogVisible = true
    },
    viewDialogClosed () {
      this.$refs.viewFormRef.resetFields()
    },

    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.seachChange()

    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.seachChange()
    },
    // 

    // 审批内容
    DesText () {
      this.wordnum = this.feedbackText.length
    },
    ApprovalDialogClosed () {
      this.feedbackText = ''
    },

    async ResultApproval (quiese) {
      if (quiese.productRelationId) {
        const res = await getAudits(quiese)
        if (res.status !== 200) return this.$message.error('审核失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.ApprovalDialog = false
        this.getList()
      } else {
        return this.$message.error('请选择联系方式')
      }
    },
    DownFile () {
      DownFiles(this.approvalForm.id, this.versionId, this.queryinfo.type, this.downType)
    },
    UnpassApproval () {
      //status = 2 不通过 this.feedbackText,resourceId
      let quiese = { auditInfo: this.feedbackText, orderId: this.ApproveId, status: 2, productRelationId: this.contactPhone }
      this.ResultApproval(quiese)
    },
    PassApproval () {
      //status = 1 通过 this.feedbackText ,resourceId
      let quiese = { auditInfo: this.feedbackText, orderId: this.ApproveId, status: 1, productRelationId: this.contactPhone }
      this.ResultApproval(quiese)

    }
  }
}
</script>
<style lang="less" scoped>
.el-menu-item {
  font-size: 16px;
  padding: 0 50px;
}
.pp {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-left: 30px;
}
.link1 {
  margin-left: 50px;
  font-size: 16px;
}
.step1 {
  // height: 250px;
  // margin-top: 10px;
  font-size: 16px;
}
.input1 {
  width: 400px;
}

.elDescribe {
  word-wrap: break-word;
  word-break: break-all;
  overflow: hidden;
  text-align: left;
  left: 4px;
  font-size: 15px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 10px;
  width: 150px;
}

/deep/ .el-descriptions-item__content {
  word-break: break-word;
  overflow-wrap: break-word;
}

.divSpan {
  font-size: 16px;
}
/deep/ .el-form-item__content {
  margin-right: 40px;
}
</style>