<template>
  <div>
    <swiper :options="swiperOption" ref="mySwiper">
      <swiper-slide v-for="(banner, index) in bannerList.imgList" :key="index">
        <!-- style="border: 1px solid #DCDFE6;" -->
        <!-- <img :src="banner.img" 
          preview="1"
         :style="[
            true
              ? {
                  height: Height + 'px',
                  width: '100%',
                }
              : {},
          ]"/> -->

        <!--  -->
        <el-image
          :src="banner.img"
          fit="contain"
          :preview-src-list="srcList"
          :style="[
            true
              ? {
                  height: Height ? Number(Height) + 'px' : '100%',
                  width: '100%',
                }
              : {},
          ]"
        ></el-image>
      </swiper-slide>

      <!-- 分页器 -->
      <div
        class="swiper-pagination"
        slot="pagination"
        v-show="bannerList.imgList.length > 1"
      ></div>

      <!-- 左右箭头 -->
      <!-- <div class="swiper-button-prev" slot="button-prev" v-show="bannerList.imgList.length > 1"></div>
      <div class="swiper-button-next" slot="button-next" v-show="bannerList.imgList.length > 1"></div> -->

      <!-- 滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
    </swiper>
  </div>
</template>
<script>

export default {
  props: {
    bannerList: {
      type: Object,
      required: true
    },
    srcList: {
      type: Array
    },
    Height: {
      type: [Number, String],
    }
  },
  data () {
    return {
      // 轮播图
      swiperOption: {
        // loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,

        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        slidesPerView: 1,
        pagination: {
          el: ".swiper-pagination",
          clickable: true, // 允许点击小圆点跳转

        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  },
}
</script>
<style lang="less" scoped>
.swiper-pagination {
  /deep/.swiper-pagination-bullet {
    background: #f39938;
    border: 2px solid white;
  }
}
</style>