<template>
  <div>
    <el-row :gutter="30" class="roww1">
      <div style="display: -webkit-flex; display: flex">
        <el-col :span="Info.sign ? 13 : 15" class="roww1">
          <elswiper
            :bannerList="bannerList"
            class="image11"
            :srcList="srcList"
            :Height="Info.sign ? '500' : ''"
          ></elswiper>
        </el-col>
        <el-col :span="Info.sign ? 9 : 7">
          <div class="div_all">
            <div class="DIV0" style="display: flex">
              <span class="span001">{{ Info.name }}</span>
            </div>
            <div class="DIV0" style="display: flex">
              <span
                style="color: #787878"
                class="span0011"
                :title="Info.description"
                >{{ Info.description }}</span
              >
            </div>

            <div class="DIV0">
              <el-tag
                type=""
                effect="plain"
                style="margin-right: 10px; margin-bottom: 10px; font-size: 14px"
                v-for="(item1, index1) in Info.Labels"
                :key="index1"
                >{{ item1 }}</el-tag
              >
            </div>
            <!-- 合作伙伴商品 -->
            <div v-if="Info.sign">
              <template v-if="Info.mode == 2">
                <div class="sku" v-for="sku in skuList" :key="sku.productSpecsId">
                  <div class="sku-label">{{sku.specsName}}：</div>
                  <div class="sku-btn">
                    <span @click="onSkuClick(sku, value)" v-for="value in sku.valueList" :key="value.specsId" class="sku-btn-tag " :class="{active:value.active}">{{value.value}}</span>
                  </div>
                </div>
                <div class="sku">
                  <div class="sku-label">数量：</div>
                  <div class="sku-btn">
                    <el-input-number
                      size="small"
                      v-model="curNum" :min="1" :max="curStockNum" :step="1"></el-input-number>
                  </div>
                </div>
                <div class="sku-price" v-if="skuAllSelected">￥{{curPrice}}</div>
                <div class="sku-price" v-else>￥{{ Info.price.price * Info.price.discount}}</div>
              </template>
              <!-- 免费 -->
              <div class="free" v-if="Info.mode == 0"></div>
              <!-- 一次性收费 -->
              <div class="once" v-if="Info.mode == 1">
                <div class="sku-price">￥{{ Info.price.price * Info.price.discount}}</div>
              </div>
            </div>
            <!-- 普通商品 -->
            <div v-else>
              <div class="DIV0" style="display: flex; margin-top: 20px">
                <span style="color: #787878">资源作者：{{ Info.author }}</span>
              </div>

              <div class="DIV0" style="display: flex; margin-top: 20px">
                <span style="color: #787878"
                  >资源类型：
                  {{ dictMap[Info.type] }}
                </span>
              </div>

              <div class="DIV0" style="display: flex">
                <span style="color: #787878"
                  >更新时间：{{ Info.updateTime }}</span
                >
              </div>

              <div
                class="DIV0"
                style="display: flex"
                v-if="versionInfo.versionList.length === 1"
              >
                <span style="color: #787878"
                  >资源大小：{{ versionInfo.size }}</span
                >
              </div>
              <div
                class="DIV0"
                style="display: flex"
                v-if="versionInfo.versionList.length > 1"
              >
                <span style="color: #787878"
                  >资源版本：{{ versionInfo.versionList.length }}个</span
                >
              </div>
            </div>
            <!-- 价格 -->
              <!-- 免费商品 -->
            <div
              style="margin-top: 20px"
                v-if="!Info.sign && Info.mode != 2"
            >
              <div v-if="!(Info.price.price > 0)">
                <span class="span11" style="color: #f39938">免费</span>
              </div>
              <div v-else>
                <span class="span11" style="color: #f39938"
                  >￥{{ Info.price.price * Info.price.discount
                  }}<span
                    class="span11"
                    style="text-decoration: line-through; color: #ccc"
                    v-if="Info.price.discount < 1"
                    >{{ Info.price.price }}</span
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="div_button">
            <!-- ****************************** 普通商品 单版本 或 合作伙伴商品是在线使用 、下载使用、在线发货模式的 -->
            <!-- Info.useWay 1 在线使用 2 线下使用 3 线下发货 -->

            <div v-if="!(Info.useWay == 1 && Info.IFpayMoney)">
              <el-button
                @click="
                  Downloads(
                    Info.sign ? Info : versionInfo.versionList[0],
                    Info.chargeModeTypeList &&
                      Info.chargeModeTypeList[0] &&
                      Info.chargeModeTypeList[0].mode > 1
                      ? Info.chargeModeTypeList[Number(Info.linkClick)]
                      : ''
                  )
                "
                class="button1"
                v-if="
                  (!Info.sign && versionInfo.versionList.length === 1) ||
                  Info.useWay
                "
                :disabled="Info.useWay == 3 && Info.IFpayMoney ? true : false"
              >
                <span
                  style="color: white"
                  v-show="!(Info.useWay == 3 && Info.IFpayMoney)"
                  >{{ !Info.IFpayMoney ? "立即购买" : "立即使用" }}</span
                >
                <!-- 线下发货 -->
                <span
                  style="color: white"
                  v-show="Info.useWay == 3 && Info.IFpayMoney"
                  >请咨询服务商</span
                >
              </el-button>
            </div>
            <div v-else>
              <!-- ********************************* -->
              <!-- 在线使用 URL 二维码 1-->
              <!-- URL 按钮 -->
              <el-button
                slot="reference"
                @click="URLPath1(Info)"
                class="button1"
                v-if="!Info.detailUrlResponse"
              >
                <span style="color: white">点击使用</span></el-button
              >
              <!-- 二维码 按钮+弹框 -->
              <el-popover v-if="Info.detailUrlResponse">
                <img
                  :src="Info.detailUrlResponse.url"
                  style="max-height: 300px; width: 100%"
                  v-if="showImg"
                />
                <el-button
                  slot="reference"
                  @click="URLPath1(Info)"
                  class="button1"
                >
                  <span style="color: white">点击使用</span></el-button
                >
              </el-popover>
            </div>

            <!--  普通商品 多版本-->
            <div>
              <el-button
                @click="Downloads(versionInfo.versionList[0])"
                class="button1"
                v-if="
                  !Info.sign &&
                  versionInfo.versionList.length > 1 &&
                  !Info.IFpayMoney
                "
              >
                <span style="color: white">立即购买</span>
              </el-button>

              <el-button
                @click="addOrders"
                class="button2"
                v-if="!Info.IFpayMoney"
                >加入购物车</el-button
              >
              <!-- ************************** -->
              <el-link
                :underline="false"
                type="info"
                @click="Liked(Info)"
                class="LIKEIN"
              >
                <img
                  :src="
                    Info.Likeds == true
                      ? require('@/assets/imgs/点赞2.png')
                      : require('@/assets/imgs/点赞1.png')
                  "
                  class="imgss"
                />
              </el-link>
              <span>{{ 0 }}</span>
            </div>
          </div>

          <!-- 合作伙伴商品 -->
          <div class="pDiv" v-show="Info.sign">
            <img src="@/assets/imgs/KHCFDC_勾选.png" alt="/" class="imgsss" />
            <div class="div8">担保交易</div>
            <img src="@/assets/imgs/KHCFDC_勾选.png" alt="/" class="imgsss" />

            <div class="div8">服务全程监督</div>

            <img src="@/assets/imgs/KHCFDC_勾选.png" alt="/" class="imgsss" />
            <div class="div8">退款售后无忧</div>

            <img src="@/assets/imgs/KHCFDC_勾选.png" alt="/" class="imgsss" />
            <div class="div8">{{ Info.gradeName }}</div>
          </div>
        </el-col>
      </div>
    </el-row>

    <el-row :gutter="30" class="roww1" style="margin-top: 40px">
      <div style="display: -webkit-flex; display: flex">
        <!-- 左侧卡片 -->
        <el-col
          :span="Info.sign ? 13 : 15"
          style="margin-bottom: 50px"
          class="roww1"
        >
          <el-card>
            <!-- 合作伙伴商品和普通商品，普通商品多版本 -->
            <div v-show="Info.sign || versionInfo.versionList.length > 1">
              <el-menu
                :default-active="activeIndex"
                mode="horizontal"
                active-text-color="#2EAEED"
                class="menuEL"
              >
                <el-menu-item
                  index="1"
                  @click="PartnerProductDetail"
                  v-show="Info.sign"
                  >商品详情</el-menu-item
                >
                <el-menu-item
                  index="3"
                  @click="ServiceSupport"
                  v-show="Info.sign"
                  >服务支持</el-menu-item
                >
                <el-menu-item index="1" @click="ListVersion" v-show="!Info.sign"
                  >版本列表</el-menu-item
                >
                <el-menu-item index="2" @click="commentPublish">
                  发表评论
                </el-menu-item>
              </el-menu>
              <div v-if="activeIndex == '1'">
                <div v-if="Info.sign">
                  <div
                    v-for="(item, index) in Info.detailUrlList"
                    :key="index"
                    style="display: flex"
                  >
                    <img width="100%" height="100%" :src="item" alt="" />
                  </div>
                </div>
                <div v-else>
                  <el-table
                    :data="versionInfo.versionList"
                    :header-cell-style="{
                      textAlign: 'center',
                      background: '#E2F0FF',
                      color: 'rgba(0, 0, 0, 0.85)',
                      fontWeight: '500',
                    }"
                  >
                    <el-table-column
                      type="index"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="version"
                      label="版本号"
                      min-width="100px"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="detail"
                      label="版本介绍"
                      min-width="250px"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      prop="resPackage.size"
                      label="文件大小"
                      min-width="150px"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="resPackage.createTime"
                      label="创建时间"
                      min-width="150px"
                      align="center"
                    >
                    </el-table-column>
                    <el-table-column
                      label="操作"
                      min-width="100px"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-link
                          type="primary"
                          :underline="false"
                          @click="Downloads(scope.row)"
                        >
                          立即使用
                        </el-link>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div v-else-if="activeIndex == '3'">
                <div class="signDiv1">
                  <div class="name1">
                    售后服务时间：{{ Info.afterService.serviceTime }}
                  </div>
                  <div class="name1">
                    售后服务内容：{{ Info.afterService.serviceDetail }}
                  </div>
                  <div class="name1">
                    技术支持电话：{{ Info.afterService.phone }}
                  </div>
                  <div class="name1">
                    技术支持邮箱：{{ Info.afterService.email }}
                  </div>
                  <div class="name1" v-show="Info.afterService.guideUrl">
                    商品使用指南：<el-link
                      @click="URLPath(Info.afterService.guideUrl)"
                    >
                      {{ Info.afterService.guideName }}</el-link
                    >
                  </div>
                  <div class="name1" v-show="Info.afterService.protocolUrl">
                    商品服务协议：<el-link
                      @click="URLPath(Info.afterService.protocolUrl)"
                    >
                      {{ Info.afterService.protocolName }}</el-link
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <CommentComponent
                  :commentName="false"
                  :typeName="Info.ListType"
                  :showId="Info.id"
                ></CommentComponent>
              </div>
            </div>
            <!-- 非合作伙伴商品，单版本 -->
            <div v-show="!Info.sign && versionInfo.versionList.length == 1">
              <CommentComponent
                :typeName="Info.ListType"
                :showId="Info.id"
              ></CommentComponent>
            </div>
          </el-card>
        </el-col>
        <!-- 右侧卡片 -->
        <el-col :span="Info.sign ? 9 : 7" style="margin-bottom: 50px">
          <el-card>
            <div v-if="Info.sign" class="signDiv">
              <div class="title">服务商</div>
              <div class="name1">售后服务</div>
            </div>
            <div v-else>
              <el-row>
                <span class="tagss">相似{{ Info.ListTypeName }} </span>
                <el-link
                  :underline="false"
                  type="info"
                  class="tagss1"
                  @click="changeTochange"
                  >换一换</el-link
                >
              </el-row>
              <EmptyEL></EmptyEL>
            </div>
          </el-card>
        </el-col>
      </div>
    </el-row>
  </div>
</template>
<script>
import CommentComponent from '@/components/myself/poductDetail/comment/comment2'
import elswiper from '@/components/myself/awSwiper/awesomeSwiper.vue'
import EmptyEL from '@/components/myself/el_empty.vue'
import { GetCacheTopAll } from "@/api/cacheApi/index";

export default {
  components: { CommentComponent, elswiper, EmptyEL },
  props: {
    bannerList: {
      type: Object
    },
    srcList: {
      type: Array
    },
    Info: {
      type: Object
    },
    versionInfo: {
      type: Object
    },
    likecount: {
      type: Number
    },
    SimList: {
      type: Array
    },
    listLength: {
      type: Number
    },
    activeIndex: {
      type: String
    },
  },
  computed: {
    curPrice() {
      return (this.price * this.curNum).toFixed(2);
    },
  },
  watch: {
    Info: {
      handler: function () {
        this.priceTable = this.Info.specsPriceDTOList;
        this.skuList = this.Info.specsDTOList.map(specs =>{
          return {
            ...specs,
            selected: undefined,
            valueList: specs.valueList.map(value => {
              return {
                ...value,
                active: false
              }
            })
          }
        });
      },
      deep: true
    },
    skuList: {
      handler: function (newVal) {
        const hasSelected = newVal.filter(sku => sku.selected);
        // 全部选中
        if (newVal.length === hasSelected.length && newVal.length > 0) {
        // 从table中找到对应的价格
          const target =
            this.priceTable.find(({ specsPriceBDTOS = [] }) => {
              return newVal.every((item) => {
                return specsPriceBDTOS.some(
                  (specs) => specs.specsId == item.selected
                );
              });
            }) || {};
          this.price = target.price || 0;
          this.skuAllSelected = true;
          this.curStockNum = target.stockNum;
          if(this.curNum > target.stockNum){
            this.curNum = target.stockNum;
          }
          this.skuId = target.specsPriceId;
        }
      },
      deep: true
    }
  },
  data () {
    return {
      showImg: false,
      priceTable: [],
      skuList: [],
      curNum: 1,
      price: 0,
      skuId: undefined,
      skuAllSelected: false,
      curStockNum: 1,
      dictMap: {},
    }
  },
  mounted() {
    this.initDict();
  },
  methods: {
    async initDict() {
      const data = await GetCacheTopAll();
      data.forEach((item) => {
        this.dictMap[item.itemType] = item.typeName;
      });
    },
    onSkuClick (sku, value) {
      sku.valueList.forEach(item => {
        item.active = item.value == value.value;
      });
      sku.selected = value.specsId;
    },

    Downloads (item) {
      this.$emit('Downloads', item)
    },
    addOrders () {
      this.$emit('addOrders')
    },
    Liked (item) {
      this.$emit('Liked', item)
    },
    changeTochange () {
      this.$emit('changeTochange')
    },
    Details (item) {
      this.$emit('Details', item)
    },
    ListVersion () {
      this.$emit('ListVersion')

    },
    commentPublish () {
      this.$emit('commentPublish')
    },
    PartnerProductDetail () {
      this.$emit('PartnerProductDetail')
    },
    ServiceSupport () {
      this.$emit('ServiceSupport')
    },
    URLPath (URL) {
      window.open(URL)
    },
    URLPath1 (info) {
      if (info.detailUrlResponse) {
        this.showImg = true
      } else {
        this.showImg = false
        window.open(info.url)
      }
    },
    TagMode (index) {
      this.$emit('TagMode', index)
    },
  }
}
</script>
<style lang="less" scoped>
.sku{
  margin-top: 10px;
  margin-bottom: 10px;
  line-height: 1.5;
  display: flex;
  &-label{
    color: #787878;
    font-size: var(--Font16);
    flex-shrink: 0;
    margin-right: 5px;
    line-height: 30px;
  }
  &-btn{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    &-tag{
      font-size: var(--Font15);
      color: #666;
      margin-bottom: 15px;
      min-width: 30px;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 3px 10px;
      margin-right: 5px;
      cursor: pointer;
      &.active{
        border: 1px solid #f39938;
        color: #f39938;
      }

    }
  }
}
.sku-price {
  font-size: var(--Font30);
  color: #f39938;
}
.buttonEl {
  color: #f39938;
}
.buttonEls {
  color: #787878;
}
.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background-color: grey;
}
@media screen and (min-width: 1500px) {
  .div_button {
    text-align: left;
    font-size: var(--Font16);
    margin-top: 40px;
    display: flex;
  }

  .button1 {
    min-width: 160px;
    background: #f39938;
    border-radius: 5px;
    font-size: var(--Font16);
    margin-bottom: 10px;
  }
  .button2 {
    margin-left: 10px;
    min-width: 160px;
    color: #f39938;
    border: 1px solid #f39938;
    border-radius: 5px;
    font-size: var(--Font16);
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 1500px) {
  .div_button {
    text-align: left;
    font-size: var(--Font16);
    margin-top: 40px;
  }
  .button1 {
    // margin-top: 40px;
    min-width: 90%;
    background: #f39938;
    border-radius: 5px;
    font-size: var(--Font16);
    margin-bottom: 10px;
  }
  .button2 {
    margin-left: 0px;
    min-width: 90%;
    color: #f39938;
    border: 1px solid #f39938;
    border-radius: 5px;
    font-size: var(--Font16);
    margin-bottom: 10px;
  }
}

.LIKEIN {
  margin-left: 20px;
  margin-right: 5px;
}
.div_all {
  margin-left: 10px;
  text-align: left;
  margin-right: 10px;
  word-break: break-all;
  word-break: break-word;
  font-size: var(--Font16);
}
.DIV0 {
  margin-top: 20px;
}
.DIV001 {
  margin-top: 40px;
}
.span0011 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  word-break: break-all;
  word-break: break-word;
}
.span001 {
  font-size: var(--Font18);
  color: #303030;
  font-weight: var(--fontWeight7);
  word-break: break-all;
  word-break: break-word;
}

.imgss {
  height: 40px;
}

.image11 {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  // text-align:center;
  vertical-align: middle;
}
.row00 {
  display: flex;
  flex-flow: wrap;
  font-size: var(--Font16);
}

.tagss {
  font-size: var(--Font18);
  font-weight: var(--fontWeight7);
  color: #303030;
  float: left;
  margin-left: 10px;
}
.tagss1 {
  float: right;
  margin-right: 10px;
  font-size: var(--Font16);
}

.div10 {
  margin-top: 5px;
}

.div11 {
  margin-top: 20px;
}

.span11 {
  font-size: var(--Font30);
}
.roww1 {
  margin-left: 100px;
}
.buttonDetail {
  font-size: var(--Font16);
  opacity: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: #f3f3f3;
  // margin-right: 1px;
  word-break: break-all;
  word-break: break-word;
  text-align: left;
}

/deep/ .el-empty__description p {
  color: #787878;
}

/deep/ .menuEL .el-menu-item {
  font-size: var(--Font18);
  font-weight: var(--fontWeight7);
}
.menuEL {
  margin-bottom: 40px;
}

.pDiv {
  display: flex;
  font-size: var(--Font14);
  font-weight: var(--fontWeight);
  font-family: var(--fontFamily1);
  color: #4c4c4c;
  margin-top: 20px;
  // margin-left: 20px;
  .imgsss {
    height: 15px;
    width: 15px;
    margin-top: 2px;
    margin-right: 5px;
  }
  .div8 {
    margin-right: 20px;
  }
}

.signDiv {
  text-align: left;
  margin-left: 20px;
  .title {
    height: 17px;
    font-size: var(--Font18);
    font-family: var(--fontFamily1);
    font-weight: var(--fontWeight);
    color: #4c4c4c;
    margin-bottom: 40px;
    margin-top: 20px;
  }
  .name1 {
    font-size: var(--Font16);
    font-family: var(--fontFamily1);
    font-weight: var(--fontWeight);
    color: #939393;
    margin-bottom: 40px;
  }
}
.signDiv1 {
  text-align: left;
  margin-left: 10%;
  .name1 {
    font-size: var(--Font16);
    font-family: var(--fontFamily1);
    font-weight: var(--fontWeight);
    color: #939393;
    margin-bottom: 40px;
  }
}
</style>